<template>
    <div class="module">
        <van-nav-bar title="电话广播" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="15" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div class="status" v-if="item.type === 1">
                    运行状态：
                    <van-tag size="medium" type="primary" v-if="item.status">正常</van-tag>
                    <van-tag size="medium" type="danger" v-else>故障</van-tag>
                </div>
                <div class="status" v-else-if="item.type === 2">
                    运行状态：
                    <van-tag size="medium" type="success" v-if="item.status">待机</van-tag>
                    <van-tag size="medium" type="warning" v-else>故障</van-tag>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
export default {
    data(){
        return{
            devList:[
                {name:'电话-进京T1',type:1,status:1,time:'2021-11-01 12:30'},
                {name:'电话-进京T2',type:1,status:1,time:'2021-11-01 08:30'},
                {name:'电话-进京T3',type:1,status:1,time:'2021-11-01 11:30'},
                {name:'电话-进京T4',type:1,status:1,time:'2021-11-01 09:30'},
                {name:'电话-出京T1',type:1,status:1,time:'2021-11-01 12:30'},
                {name:'电话-出京T2',type:1,status:1,time:'2021-11-01 02:20'},
                {name:'电话-出京T3',type:1,status:1,time:'2021-11-01 12:30'},
                {name:'电话-出京T4',type:1,status:1,time:'2021-11-02 12:00'},
                {name:'广播-进京T1',type:2,status:1,time:'2021-11-02 12:10'},
                {name:'广播-进京T2',type:2,status:1,time:'2021-11-02 14:30'},
                {name:'广播-进京T3',type:2,status:1,time:'2021-11-02 14:50'},
                {name:'广播-进京T4',type:2,status:1,time:'2021-11-02 15:30'},
                {name:'广播-出京T1',type:2,status:1,time:'2021-11-02 16:00'},
                {name:'广播-出京T2',type:2,status:1,time:'2021-11-02 18:10'},
                {name:'广播-出京T3',type:2,status:1,time:'2021-11-02 19:00'},
                {name:'广播-出京T4',type:2,status:1,time:'2021-11-02 19:10'},
                {name:'广播-出京T5',type:2,status:0,time:'2021-11-02 19:10'},
            ]
        }
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>